import mapboxgl from "mapbox-gl";

type _MapboxOptions = Omit<mapboxgl.MapboxOptions, "container">;

interface MapboxGLOverlayViewOpts extends _MapboxOptions {
  container?: string | HTMLElement;
  mapPane?: keyof google.maps.MapPanes;
}

// https://en.wikipedia.org/wiki/Web_Mercator_projection#Formulas
const MAX_LATITUDE = 85.05113;

/**
 * Get the current view state
 * see https://github.com/visgl/deck.gl/blob/master/modules/google-maps/src/utils.js#L79
 **/
function getViewState(
  map: google.maps.Map,
  overlay: google.maps.OverlayView,
  gmapsns: any /** google.maps namespace */
) {
  // The map fills the container div unless it's in fullscreen mode
  // at which point the first child of the container is promoted
  const container = map.getDiv().firstChild;
  let width = 1;
  let height = 1;
  if (container) {
    const cc = container as HTMLDivElement;
    width = cc.offsetWidth;
    height = cc.offsetHeight;
  }

  // Canvas position relative to draggable map's container depends on
  // overlayView's projection, not the map's. Have to use the center of the
  // map for this, not the top left, for the same reason as above.
  const projection = overlay.getProjection();

  const bounds = map.getBounds();
  if (!bounds) {
    return null;
  }
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  const topRight = projection.fromLatLngToDivPixel(ne);
  const bottomLeft = projection.fromLatLngToDivPixel(sw);

  // google maps places overlays in a container anchored at the map center.
  // the container CSS is manipulated during dragging.
  // We need to update left/top of the deck canvas to match the base map.
  const nwContainerPx = new gmapsns.Point(0, 0);
  const nw = projection.fromContainerPixelToLatLng(nwContainerPx);
  const nwDivPx = projection.fromLatLngToDivPixel(nw);
  let leftOffset = nwDivPx?.x || 0;
  let topOffset = nwDivPx?.y || 0;

  // Adjust horizontal offset - position the viewport at the map in the center
  const mapWidth = projection.getWorldWidth();
  const mapCount = Math.ceil(width / mapWidth);
  leftOffset -= Math.floor(mapCount / 2) * mapWidth;

  // Compute fractional zoom.
  const scale = height
    ? ((bottomLeft?.y || 0) - (topRight?.y || 0)) / height
    : 1;
  // When resizing aggressively, occasionally ne and sw are the same points
  // See https://github.com/visgl/deck.gl/issues/4218
  const zoom = Math.log2(scale || 1) + (map?.getZoom() || 0) - 1;

  // Compute fractional center.
  let centerPx = new gmapsns.Point(width / 2, height / 2);
  const centerContainer = projection.fromContainerPixelToLatLng(centerPx);
  let latitude = centerContainer?.lat() || 0;
  const longitude = centerContainer?.lng() || 0;

  // Adjust vertical offset - limit latitude
  if (Math.abs(latitude) > MAX_LATITUDE) {
    latitude = latitude > 0 ? MAX_LATITUDE : -MAX_LATITUDE;
    const center = new gmapsns.LatLng(latitude, longitude);
    centerPx = projection.fromLatLngToContainerPixel(center);
    topOffset += centerPx.y - height / 2;
  }

  return {
    width,
    height,
    left: leftOffset,
    top: topOffset,
    zoom,
    pitch: map.getTilt(),
    latitude,
    longitude,
  };
}

/**
 * This is google.maps.OverlayView manager to display layer MapboxGL on Google Maps.
 *
 * Default rendered on Google MapPanes=overlayLayer (which does not receive DOM events)
 * MapboxOptions below may be needed if mappane=overlayMouseTarget
 *   - scrollZoom: false,
 *   - interactive: false, // if interactive true: keyboard listener. i.e. hit arrow ke, move mapboxgl layer, not GMap
 *
 * This package don't have access to global object `google` or `mapboxgl`
 *
 * Reference implementation from deck.gl
 * https://github.com/visgl/deck.gl/blob/master/modules/google-maps/src/google-maps-overlay.js
 */
class MapboxGLOverlayView {
  mapboxglContainer?: HTMLElement;
  mapboxgl?: mapboxgl.Map;
  overlay: google.maps.OverlayView;
  googlemap: google.maps.Map | null;
  opts: MapboxGLOverlayViewOpts;
  /** google.maps namespace */
  maps: any;
  /** mapboxgl namespace */
  mapboxglns: any;

  constructor(
    map: google.maps.Map,
    /** google.maps namespace */
    maps: any,
    /** mapboxgl namespace */
    mapboxlgns: any,
    opts?: MapboxGLOverlayViewOpts
  ) {
    const overlay = new maps.OverlayView();
    overlay.onAdd = this._onAdd;
    overlay.onRemove = this._onRemove;
    overlay.draw = this._draw;
    overlay.setMap(map);
    this.overlay = overlay;
    this.maps = maps;
    this.mapboxglns = mapboxlgns;
    this.opts = { mapPane: "overlayLayer", ...opts };
    if (map === undefined) {
      this.googlemap = null;
    } else {
      this.googlemap = map;
    }
    this.initMapgl();
  }

  _onAdd = () => {
    const map = this.getMap();
    if (this.mapboxgl === null) {
      this.initMapgl();
    }
    if (!map) {
      return;
    }

    if (!this.opts.mapPane) {
      return;
    }
    if (!this.mapboxglContainer) {
      return;
    }
    if (this.mapPane && this.opts.mapPane) {
      this.mapPane(this.opts.mapPane)?.appendChild(this.mapboxglContainer);
    }
  };

  _onRemove = () => {
    if (this.mapboxgl) {
      this.mapboxgl.remove();
    }
  };

  _draw = () => {
    if (!this.mapboxgl) {
      return;
    }

    const googlemap = this.getMap();
    if (!googlemap) {
      return;
    }

    const mapboxglcontainer = this.mapboxglContainer;
    if (!mapboxglcontainer) {
      return;
    }
    if (mapboxglcontainer.style.visibility === "hidden") {
      return;
    }

    const vs = getViewState(googlemap, this.overlay, this.maps);
    if (!vs) {
      return;
    }
    const isSameDim =
      parseInt(mapboxglcontainer.style.width, 10) === vs?.width &&
      parseInt(mapboxglcontainer.style.height, 10) === vs?.height;
    mapboxglcontainer.style.width = vs?.width + "px";
    mapboxglcontainer.style.height = vs?.height + "px";
    mapboxglcontainer.style.left = vs?.left + "px";
    mapboxglcontainer.style.top = vs?.top + "px";

    const mgl = this.mapboxgl;
    if (vs?.latitude && vs.latitude) {
      mgl.setCenter([vs.longitude, vs.latitude]);
    }
    if (vs?.zoom) {
      mgl.setZoom(vs.zoom); // vector tiles zoom level != static tile
    }
    if (isSameDim) {
      mgl.triggerRepaint();
    } else {
      mgl.resize();
    }
  };

  getMap() {
    return this.googlemap;
  }

  setMap(map: google.maps.Map) {
    if (map === this.googlemap) {
      return;
    }
    if (this.googlemap) {
      this.overlay.setMap(null);
      this.googlemap = null;
    }
    if (map) {
      this.googlemap = map;
      this.overlay.setMap(map);
    }
  }

  getPixel(latLng: google.maps.LatLng): mapboxgl.PointLike {
    const c = this.overlay.getProjection().fromLatLngToContainerPixel(latLng);
    if (!c) {
      return [0, 0];
    }
    return [c.x, c.y];
  }

  mapPane(pane: keyof google.maps.MapPanes) {
    const panes = this.overlay.getPanes();
    if (!panes) {
      return;
    }
    switch (pane) {
      case "floatPane":
        return panes.floatPane;

      case "mapPane":
        return panes.mapPane;

      case "markerLayer":
        return panes.markerLayer;

      case "overlayLayer":
        return panes.overlayLayer;

      case "overlayMouseTarget":
        return panes.overlayMouseTarget;

      default:
        return panes.overlayLayer;
    }
  }

  initMapgl() {
    if (!this.opts.container) {
      const container = document.createElement("div");
      this.mapboxglContainer = container;
      this.opts.container = container;
    }
    this.mapboxgl = new this.mapboxglns.Map(
      this.opts as mapboxgl.MapboxOptions
    );
    const map = this.getMap();
    if (!map) {
      return;
    }
  }

  hide() {
    if (this.mapboxglContainer) {
      this.mapboxglContainer.style.visibility = "hidden";
    }
  }

  show() {
    if (this.mapboxglContainer) {
      this.mapboxglContainer.style.visibility = "visible";
    }
  }

  toggle() {
    if (this.mapboxglContainer) {
      if (this.mapboxglContainer.style.visibility === "hidden") {
        this.show();
      } else {
        this.hide();
      }
    }
  }

  toggleDOM() {
    const map = this.getMap();
    this.overlay.setMap(map);
  }
}

export default MapboxGLOverlayView;
